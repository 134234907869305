import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import orgStore from '@/store/orgs'
import notificationsStore from '@/store/notifications'
import SessionService from '@/services/session_service'
import atlas from '@/lib/atlas'
import analytics from '@/lib/analytics'
import { getCurrentUser } from '@/lib/use_current_user'
import stackStore from '@/store/stacks'
import { authorizeOrgMember } from '@/lib/authorization/org_authorization'
import { authorizeStackSoftwareEngineer } from '@/lib/authorization/stack_authorization'
import { Dictionary } from '@/models/dictionary'
import PRPreviewAppsService from '@/services/pr_preview_apps_service'
import errorHandler from '@/lib/error_handler'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/components/examples/chart-dashboard',
    name: 'examples-chart-dashboard',
    meta: { layout: 'empty', requireAuth: false, example: true },
    component: () => import('./components/examples/chart-dashboard/Index.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: { layout: 'empty', requireAuth: false },
    component: () => import(/* webpackChunkName: "users" */ './views/users/Login.vue'),
    beforeEnter: (to: RouteLocationNormalized) => {
      const currentUser = getCurrentUser()
      if (currentUser.isAuthenticated) {
        // the user is attempting to go to the login page but they are already logged in
        // take them to the redirect_url or the root (root will then redirect to the correct page)
        if (to.query.redirect_url) {
          location.href = to.query.redirect_url as string
        } else {
          return { name: 'root' }
        }
      }
    }
  },
  {
    path: '/register',
    name: 'register',
    meta: { layout: 'empty', requireAuth: false },
    component: () => import(/* webpackChunkName: "users" */ './views/users/Register.vue'),
    beforeEnter: (to: RouteLocationNormalized) => {
      const currentUser = getCurrentUser()
      if (currentUser.isAuthenticated) {
        // the user is attempting to go to the register page but they are already logged in
        // take them to the redirect_url or the root (root will then redirect to the correct page)
        if (to.query.redirect_url) {
          location.href = to.query.redirect_url as string
        } else {
          return { name: 'root' }
        }
      }
    }
  },
  {
    // we are using :newOrg on purpose here so that the user can access this before they are a member of the org
    // this avoids the authorization check below in our beforeEach guard
    path: '/orgs/:newOrg/user_invite/:id',
    name: 'user_invite',
    meta: { layout: 'org' },
    component: () => import(/* webpackChunkName: "users" */ './views/organizations/UserInvite.vue')
  },
  {
    path: '/users/passwords',
    name: 'create_password',
    meta: { layout: 'empty', requireAuth: false },
    component: () => import(/* webpackChunkName: "users" */ './views/users/passwords/Create.vue')
  },
  {
    path: '/users/passwords/edit',
    name: 'edit_password',
    meta: { layout: 'empty', requireAuth: false },
    component: () => import(/* webpackChunkName: "users" */ './views/users/passwords/Edit.vue')
  },
  {
    path: '/profile',
    name: 'show_profile',
    component: () => import(/* webpackChunkName: "users" */ './views/users/Profile.vue')
  },
  {
    path: '/orgs/:orgName',
    name: 'home',
    component: {},
    beforeEnter: async (to: RouteLocationNormalized) => {
      // any time we arrive here we want to redirect on to the stack page
      const orgName = to.params.orgName as string
      const stackId = await resolveStackId(orgName)
      if (stackId) {
        return { name: 'show_stack', params: { orgName, stackId } }
      }
      return { name: 'new_stack', params: { orgName } }
    }
  },
  {
    path: '/orgs/:orgName/settings',
    name: 'show_organization',
    component: () => import(/* webpackChunkName: "organizations" */ './views/organizations/Show.vue'),
    beforeEnter: (to) => {
      if (to.name === 'show_organization') {
        return { name: 'organization_members', params: to.params }
      }
    },
    children: [
      {
        name: 'organization_subscription',
        path: 'subscription',
        component: () => import(/* webpackChunkName: "organizations" */ './views/organizations/show/Subscription.vue')
      },
      {
        name: 'organization_members',
        path: 'members',
        component: () => import(/* webpackChunkName: "organizations" */ './views/organizations/show/Members.vue')
      }
    ]
  },
  {
    path: '/orgs/:orgName/subscriptions/new',
    name: 'new_subscription',
    meta: { layout: 'org', title: 'Checkout' },
    component: () => import(/* webpackChunkName: "organizations" */ './views/organizations/subscriptions/New.vue')
  },
  {
    path: '/orgs/:orgName/stacks/new',
    name: 'new_stack',
    component: () => import(/* webpackChunkName: "stacks" */ './views/stacks/New.vue')
  },
  {
    path: '/orgs/:orgName/stacks/:stackId',
    name: 'show_stack',
    component: () => import(/* webpackChunkName: "stacks" */ './views/stacks/Show.vue'),
    beforeEnter: (to: RouteLocationNormalized) => {
      if (to.name === 'show_stack') {
        return { name: 'stack_overview', params: to.params, query: to.query }
      }
    },
    children: [
      {
        path: 'overview',
        name: 'stack_overview',
        component: () => import(/* webpackChunkName: "stacks" */ './views/stacks/show/Overview.vue')
      },
      {
        path: 'gitops',
        name: 'stack_gitops',
        component: () => import(/* webpackChunkName: "stacks" */ './views/stacks/show/Gitops.vue'),
        children: [
          {
            path: 'new',
            name: 'new_gitops_connection',
            component: () => import(/* webpackChunkName: "stacks" */ './views/stacks/show/gitops/New.vue')
          },
          {
            path: 'connections/:connectionId',
            name: 'edit_gitops_connection',
            component: () => import(/* webpackChunkName: "stacks" */ './views/stacks/show/gitops/Edit.vue')
          }
        ]
      },
      {
        path: 'envs',
        name: 'envs',
        component: () => import(/* webpackChunkName: "stacks" */ '@/views/envs/Index.vue')
      },
      {
        path: 'members',
        name: 'stack_members',
        component: () => import(/* webpackChunkName: "stacks" */ './views/stacks/show/Members.vue')
      },
      {
        path: 'settings',
        name: 'stack_settings',
        component: () => import(/* webpackChunkName: "stacks" */ './views/stacks/show/Settings.vue')
      }
    ]
  },
  {
    path: '/orgs/:orgName/stacks/:stackId/environments/:envId',
    name: 'show_env_old',
    component: {},
    beforeEnter: (to: RouteLocationNormalized) => {
      return { name: 'show_env', params: to.params, query: to.query }
    }
  },
  {
    path: '/orgs/:orgName/stacks/:stackId/envs/:envId',
    name: 'show_env',
    component: () => import(/* webpackChunkName: "envs" */ '@/views/envs/Show.vue'),
    beforeEnter: (to: RouteLocationNormalized) => {
      if (to.name === 'show_env') {
        return { name: 'env_overview', params: to.params, query: to.query }
      }
    },
    children: [
      {
        path: 'overview',
        name: 'env_overview',
        component: () => import(/* webpackChunkName: "envs" */ '@/views/envs/show/Overview.vue')
      },
      {
        path: 'activity',
        name: 'env_activity',
        component: () => import(/* webpackChunkName: "envs" */ '@/views/envs/show/Activity.vue'),
        children: [
          {
            name: 'env_workflow',
            path: 'workflows/:workflowId',
            component: () => import(/* webpackChunkName: "blocks" */ './views/envs/show/activity/Workflow.vue')
          }
        ]
      },
      {
        path: 'events',
        name: 'env_events',
        component: () => import(/* webpackChunkName: "envs" */ '@/views/envs/show/Events.vue'),
        children: [
          {
            path: 'new',
            name: 'new_env_event',
            component: () => import(/* webpackChunkName: "envs" */ './views/envs/show/events/New.vue')
          },
          {
            path: ':eventUid',
            name: 'edit_env_event',
            component: () => import(/* webpackChunkName: "envs" */ './views/envs/show/events/Edit.vue')
          }
        ]
      },
      {
        path: 'settings',
        name: 'env_settings',
        component: () => import(/* webpackChunkName: "envs" */ '@/views/envs/show/Settings.vue')
      }
    ]
  },
  {
    path: '/orgs/:orgName/stacks/:stackId/environments/new_preview',
    name: 'new_preview_env_old',
    component: {},
    beforeEnter: (to: RouteLocationNormalized) => {
      return { name: 'new_preview_env', params: to.params, query: to.query }
    }
  },
  {
    path: '/orgs/:orgName/stacks/:stackId/envs/new_preview',
    name: 'new_preview_env',
    component: () => import(/* webpackChunkName: "envs" */ './views/preview_envs/New.vue'),
    beforeEnter: async (to: RouteLocationNormalized) => {
      if (to.query.pull_request_id) {
        const orgName = to.params.orgName as string
        const stackId = parseInt(to.params.stackId as string)
        const pullRequestId = parseInt(to.query.pull_request_id as string)
        const previewApps = await PRPreviewAppsService.query(orgName, stackId, pullRequestId)
        if (previewApps && previewApps.length > 0) {
          return { name: 'show_env', params: { orgName, stackId, envId: previewApps[0].envId } }
        }
      }
    }
  },
  {
    path: '/orgs/:orgName/stacks/:stackId/blocks/new',
    name: 'new_block',
    component: () => import(/* webpackChunkName: "blocks" */ './views/blocks/New.vue')
  },
  {
    path: '/orgs/:orgName/stacks/:stackId/blocks/:blockId',
    name: 'show_block',
    component: () => import(/* webpackChunkName: "blocks" */ './views/blocks/Show.vue'),
    beforeEnter: (to: RouteLocationNormalized) => {
      if (to.name === 'show_block') {
        return { name: 'block_overview', params: to.params, query: to.query }
      }
    },
    children: [
      {
        name: 'block_overview',
        path: 'overview',
        component: () => import(/* webpackChunkName: "blocks" */ '@/views/blocks/show/Overview.vue')
      },
      {
        name: 'block_settings',
        path: 'settings',
        component: () => import(/* webpackChunkName: "blocks" */ '@/views/blocks/show/Settings.vue')
      }
    ]
  },
  {
    path: '/orgs/:orgName/stacks/:stackId/envs/:envId/blocks/:blockId',
    name: 'show_block_env',
    component: () => import(/* webpackChunkName: "blocks" */ './views/blocks/envs/Show.vue'),
    beforeEnter: (to: RouteLocationNormalized) => {
      if (to.name === 'show_block_env') {
        return { name: 'block_env_overview', params: to.params, query: to.query }
      }
    },
    children: [
      {
        name: 'block_env_overview',
        path: 'overview',
        component: () => import(/* webpackChunkName: "blocks" */ './views/blocks/envs/show/Overview.vue')
      },
      {
        name: 'block_env_monitoring',
        path: 'monitoring',
        component: () => import(/* webpackChunkName: "blocks" */ './views/blocks/envs/show/Monitoring.vue')
      },
      {
        name: 'block_env_activity',
        path: 'activity',
        component: () => import(/* webpackChunkName: "blocks" */ './views/blocks/envs/show/Activity.vue'),
        children: [
          {
            name: 'block_env_workflow',
            path: 'workflows/:workflowId',
            component: () => import(/* webpackChunkName: "blocks" */ './views/blocks/envs/show/activity/Workflow.vue')
          }
        ]
      },
      {
        name: 'block_env_infrastructure',
        path: 'infrastructure',
        component: () => import(/* webpackChunkName: "blocks" */ './views/blocks/envs/show/Infrastructure.vue')
      },
      {
        name: 'block_env_configuration',
        path: 'configuration',
        component: () => import(/* webpackChunkName: "blocks" */ './views/blocks/envs/show/Configuration.vue')
      },
      {
        name: 'block_env_launch',
        path: 'launch',
        component: () => import(/* webpackChunkName: "blocks" */ './views/blocks/envs/show/Launch.vue')
      }
    ]
  },
  {
    path: '/orgs/:orgName/stacks/:stackId/domains/new',
    name: 'new_domain',
    component: () => import(/* webpackChunkName: "domains" */ './views/domains/New.vue')
  },
  {
    path: '/orgs/:orgName/stacks/:stackId/domains/:blockId',
    name: 'show_domain',
    component: () => import(/* webpackChunkName: "domains" */ './views/blocks/Show.vue'),
    beforeEnter: (to: RouteLocationNormalized) => {
      if (to.name === 'show_domain') {
        return { name: 'domain_overview', params: to.params, query: to.query }
      }
    },
    children: [
      {
        name: 'domain_overview',
        path: 'overview',
        component: () => import(/* webpackChunkName: "domains" */ './views/blocks/show/Overview.vue')
      },
      {
        name: 'domain_settings',
        path: 'settings',
        component: () => import(/* webpackChunkName: "domains" */ './views/blocks/show/Settings.vue')
      }
    ]
  },
  {
    path: '/orgs/:orgName/stacks/:stackId/envs/:envId/domains/:blockId',
    name: 'show_domain_env',
    component: () => import(/* webpackChunkName: "domains" */ './views/blocks/envs/Show.vue'),
    beforeEnter: (to: RouteLocationNormalized) => {
      if (to.name === 'show_domain_env') {
        return { name: 'domain_env_overview', params: to.params, query: to.query }
      }
    },
    children: [
      {
        name: 'domain_env_overview',
        path: 'overview',
        component: () => import(/* webpackChunkName: "domains" */ '@/views/domains/envs/show/Overview.vue')
      },
      {
        name: 'domain_env_activity',
        path: 'activity',
        component: () => import(/* webpackChunkName: "domains" */ './views/blocks/envs/show/Activity.vue'),
        children: [
          {
            name: 'domain_env_workflow',
            path: 'workflows/:workflowId',
            component: () => import(/* webpackChunkName: "blocks" */ './views/blocks/envs/show/activity/Workflow.vue')
          }
        ]
      },
      {
        name: 'domain_env_configuration',
        path: 'configuration',
        component: () => import(/* webpackChunkName: "blocks" */ './views/blocks/envs/show/Configuration.vue')
      },
      {
        name: 'domain_env_subdomains',
        path: 'subdomains',
        component: () => import(/* webpackChunkName: "domains" */ '@/views/domains/envs/show/Subdomains.vue')
      },
      {
        name: 'domain_env_infrastructure',
        path: 'infrastructure',
        component: () => import(/* webpackChunkName: "blocks" */ './views/blocks/envs/show/Infrastructure.vue')
      },
      {
        name: 'domain_env_launch',
        path: 'launch',
        component: () => import(/* webpackChunkName: "domains" */ './views/blocks/envs/show/Launch.vue')
      }
    ]
  },
  {
    path: '/orgs/:orgName/stacks/:stackId/domains/:domainId/subdomains/new',
    name: 'new_subdomain',
    component: () => import(/* webpackChunkName: "domains" */ './views/domains/subdomains/New.vue')
  },
  {
    path: '/orgs/:orgName/stacks/:stackId/apps/new',
    name: 'new_app',
    component: () => import(/* webpackChunkName: "apps" */ './views/apps/New.vue')
  },
  {
    path: '/orgs/:orgName/stacks/:stackId/apps/:blockId',
    name: 'show_app',
    component: () => import(/* webpackChunkName: "apps" */ './views/blocks/Show.vue'),
    beforeEnter: (to: RouteLocationNormalized) => {
      if (to.name === 'show_app') {
        return { name: 'app_overview', params: to.params, query: to.query }
      }
    },
    children: [
      {
        name: 'app_overview',
        path: 'overview',
        component: () => import(/* webpackChunkName: "apps" */ './views/blocks/show/Overview.vue')
      },
      {
        name: 'app_settings',
        path: 'settings',
        component: () => import(/* webpackChunkName: "apps" */ './views/blocks/show/Settings.vue')
      }
    ]
  },
  {
    path: '/orgs/:orgName/stacks/:stackId/envs/:envId/apps/:blockId',
    name: 'show_app_env',
    component: () => import(/* webpackChunkName: "apps" */ './views/apps/envs/Show.vue'),
    beforeEnter: (to) => {
      if (to.name === 'show_app_env') {
        return { name: 'app_env_overview', params: to.params, query: to.query }
      }
    },
    children: [
      {
        name: 'app_env_overview',
        path: 'overview',
        component: () => import(/* webpackChunkName: "apps" */ './views/apps/envs/show/Overview.vue')
      },
      {
        name: 'app_env_logs',
        path: 'logs',
        component: () => import(/* webpackChunkName: "apps" */ './views/apps/envs/show/Logs.vue')
      },
      {
        name: 'app_env_monitoring',
        path: 'monitoring',
        component: () => import(/* webpackChunkName: "blocks" */ './views/blocks/envs/show/Monitoring.vue')
      },
      {
        name: 'app_env_activity',
        path: 'activity',
        component: () => import(/* webpackChunkName: "blocks" */ './views/blocks/envs/show/Activity.vue'),
        children: [
          {
            name: 'app_env_workflow',
            path: 'workflows/:workflowId',
            component: () => import(/* webpackChunkName: "blocks" */ './views/blocks/envs/show/activity/Workflow.vue')
          }
        ]
      },
      {
        name: 'app_env_capabilities',
        path: 'capabilities',
        component: () => import(/* webpackChunkName: "apps" */ './views/apps/envs/show/Capabilities.vue')
      },
      {
        name: 'app_env_env_variables',
        path: 'env_variables',
        component: () => import(/* webpackChunkName: "apps" */ './views/apps/envs/show/EnvVariables.vue')
      },
      {
        name: 'app_env_infrastructure',
        path: 'infrastructure',
        component: () => import(/* webpackChunkName: "apps" */ './views/blocks/envs/show/Infrastructure.vue')
      },
      {
        name: 'app_env_configuration',
        path: 'configuration',
        component: () => import(/* webpackChunkName: "apps" */ './views/blocks/envs/show/Configuration.vue')
      },
      {
        name: 'app_env_launch',
        path: 'launch',
        component: () => import(/* webpackChunkName: "apps" */ './views/blocks/envs/show/Launch.vue')
      }
    ]
  },
  {
    path: '/orgs/:orgName/stacks/:stackId/datastores/new',
    name: 'new_datastore',
    component: () => import(/* webpackChunkName: "datastores" */ './views/datastores/New.vue')
  },
  {
    path: '/orgs/:orgName/stacks/:stackId/datastores/:blockId',
    name: 'show_datastore',
    component: () => import(/* webpackChunkName: "datastores" */ './views/blocks/Show.vue'),
    beforeEnter: (to: RouteLocationNormalized) => {
      if (to.name === 'show_datastore') {
        return { name: 'datastore_overview', params: to.params, query: to.query }
      }
    },
    children: [
      {
        name: 'datastore_overview',
        path: 'overview',
        component: () => import(/* webpackChunkName: "datastores" */ './views/blocks/show/Overview.vue')
      },
      {
        name: 'datastore_settings',
        path: 'settings',
        component: () => import(/* webpackChunkName: "datastores" */ './views/blocks/show/Settings.vue')
      }
    ]
  },
  {
    path: '/orgs/:orgName/stacks/:stackId/envs/:envId/datastores/:blockId',
    name: 'show_datastore_env',
    component: () => import(/* webpackChunkName: "datastores" */ './views/blocks/envs/Show.vue'),
    beforeEnter: (to) => {
      if (to.name === 'show_datastore_env') {
        return { name: 'datastore_env_overview', params: to.params, query: to.query }
      }
    },
    children: [
      {
        name: 'datastore_env_overview',
        path: 'overview',
        component: () => import(/* webpackChunkName: "blocks" */ './views/blocks/envs/show/Overview.vue')
      },
      {
        name: 'datastore_env_monitoring',
        path: 'monitoring',
        component: () => import(/* webpackChunkName: "blocks" */ './views/blocks/envs/show/Monitoring.vue')
      },
      {
        name: 'datastore_env_activity',
        path: 'activity',
        component: () => import(/* webpackChunkName: "datastores" */ './views/blocks/envs/show/Activity.vue'),
        children: [
          {
            name: 'datastore_env_workflow',
            path: 'workflows/:workflowId',
            component: () => import(/* webpackChunkName: "blocks" */ './views/blocks/envs/show/activity/Workflow.vue')
          }
        ]
      },
      {
        name: 'datastore_env_infrastructure',
        path: 'infrastructure',
        component: () => import(/* webpackChunkName: "datastores" */ './views/blocks/envs/show/Infrastructure.vue')
      },
      {
        name: 'datastore_env_configuration',
        path: 'configuration',
        component: () => import(/* webpackChunkName: "datastores" */ './views/blocks/envs/show/Configuration.vue')
      },
      {
        name: 'datastore_env_launch',
        path: 'launch',
        component: () => import(/* webpackChunkName: "datastores" */ './views/blocks/envs/show/Launch.vue')
      }
    ]
  },
  {
    path: '/orgs/:orgName/cloud_accounts',
    name: 'cloud_accounts',
    component: () => import(/* webpackChunkName: "cloud_accounts" */ './views/cloud_accounts/Index.vue')
  },
  {
    path: '/orgs/:orgName/cloud_accounts/new',
    name: 'new_cloud_account',
    component: () => import(/* webpackChunkName: "cloud_accounts" */ './views/cloud_accounts/New.vue')
  },
  {
    path: '/orgs/:orgName/cloud_accounts/:providerId',
    name: 'show_cloud_account',
    component: () => import(/* webpackChunkName: "cloud_accounts" */ './views/cloud_accounts/Show.vue')
  },
  {
    path: '/orgs/:orgName/integrations',
    name: 'integrations',
    component: () => import(/* webpackChunkName: "integrations" */ './views/integrations/Index.vue')
  },
  {
    path: '/registry',
    name: 'registry',
    meta: { title: 'Registry', requireAuth: false, layout: 'registry' },
    component: () => import(/* webpackChunkName: "registry" */ './views/registry/Index.vue'),
    beforeEnter: (to) => {
      if (to.name === 'registry') {
        return { name: 'registry_modules', params: to.params }
      }
    },
    children: [
      {
        name: 'registry_modules',
        path: 'modules',
        component: () => import(/* webpackChunkName: "registry" */ './views/registry/index/Modules.vue')
      },
      {
        name: 'registry_blueprints',
        path: 'blueprints',
        component: () => import(/* webpackChunkName: "registry" */ './views/registry/index/Blueprints.vue')
      }
    ]
  },
  {
    path: '/registry/modules/new',
    name: 'new_module',
    meta: { title: 'Register Module', layout: 'registry' },
    component: () => import(/* webpackChunkName: "registry" */ './views/registry/modules/New.vue')
  },
  {
    path: '/registry/modules/:moduleOrgName/:moduleName',
    name: 'show_module',
    meta: { title: 'Module Details', requireAuth: false, layout: 'registry_white' },
    component: () => import(/* webpackChunkName: "registry" */ './views/registry/modules/Show.vue'),
    beforeEnter: (to) => {
      if (to.name === 'show_module') {
        return { name: 'module_readme', params: to.params }
      }
    },
    children: [
      {
        path: 'readme',
        name: 'module_readme',
        component: () => import(/* webpackChunkName: "registry" */ './views/registry/modules/show/Readme.vue')
      },
      {
        path: 'inputs',
        name: 'module_inputs',
        component: () => import(/* webpackChunkName: "registry" */ './views/registry/modules/show/Inputs.vue')
      },
      {
        path: 'connections',
        name: 'module_connections',
        component: () => import(/* webpackChunkName: "registry" */ './views/registry/modules/show/Connections.vue')
      },
      {
        path: 'outputs',
        name: 'module_outputs',
        component: () => import(/* webpackChunkName: "registry" */ './views/registry/modules/show/Outputs.vue')
      },
      {
        path: 'env_variables',
        name: 'module_env_vars',
        component: () => import(/* webpackChunkName: "registry" */ './views/registry/modules/show/EnvVariables.vue')
      }
    ]
  },
  {
    path: '/new_org',
    name: 'new_organization',
    meta: { layout: 'org', title: 'Create Organization' },
    component: () => import(/* webpackChunkName: "organizations" */ './views/organizations/New.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: {},
    beforeEnter: async () => {
      await SessionService.delete()
      return { name: 'login' }
    }
  },
  {
    path: '/access-denied',
    name: 'no_access',
    meta: { layout: 'empty' },
    component: () => import(/* webpackChunkName: "not_found" */ './views/403.vue')
  },
  {
    path: '/',
    name: 'root',
    component: {},
    // the user comes to the root path when first logging in or simply navigating to app.nullstone.io
    // redirect to the home page for the org
    beforeEnter: async () => {
      const orgName = resolveOrgName()
      const stackId = await resolveStackId(orgName)
      if (stackId) {
        return { name: 'show_stack', params: { orgName, stackId } }
      }
      return { name: 'home', params: { orgName } }
    }
  },
  {
    path: '/:pathMatch(.*)',
    name: 'not_found',
    meta: { layout: 'empty' },
    component: () => import(/* webpackChunkName: "not_found" */ './views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const resolveOrgName = (): string => {
  // if the user has never logged in, the current org will be undefined
  // if currentOrg is undefined or the user doesn't have access anymore, take them to their personal org
  // if the user no longer has access to the org
  if (!orgStore.currentOrg || !hasOrgAccess(orgStore.currentOrg)) return getCurrentUser().name
  return orgStore.currentOrg
}

const resolveStackId = async (orgName: string): Promise<number | undefined> => {
  const stackId = stackStore.currentStack(orgName)
  // if any of the following 3 conditions are true, we need to ensure the stacks are loaded and navigate to the first:
  // 1. currentStack is undefined - which means the user hasn't visited this org before
  // 2. the user doesn't have access to the current stack - perhaps they were removed from the stack
  // 3. the stack was deleted and so it can't be found in the stackStore
  if (!stackId || !hasStackAccess(orgName, stackId) || !stackStore.find(orgName, stackId)) {
    await stackStore.ensureStacksLoaded(orgName)
    const stacks = stackStore.query(orgName)
    for (const stack of stacks) {
      if (hasStackAccess(orgName, stack.id)) {
        return stack.id
      }
    }
    return undefined
  }
  return stackId
}

// we enter this hook after the final route has been resolved and all redirects in the router have been followed
// make sure the user has access to the org and stack
// and make sure the orgs and stacks are loaded
router.beforeResolve(async (to: RouteLocationNormalized) => {
  if (to.meta.example === true) {
    return
  }
  notificationsStore.clear()

  // redirect the user to an access-denied page if they don't have org or stack access
  const orgName = getOrgName(to)
  const stackId = getStackId(to)

  const orgAccess = hasOrgAccess(orgName)
  const stackAccess = hasStackAccess(orgName, stackId)
  if (!orgAccess || !stackAccess) {
    const query = {} as Dictionary<any> // eslint-disable-line @typescript-eslint/no-explicit-any
    if (!orgAccess) { query.orgName = orgName }
    if (!stackAccess) { query.stackId = stackId }
    return { name: 'no_access', query }
  }

  // always ensure that the orgs and stacks are loaded before navigating to a page
  if (getCurrentUser().isAuthenticated) {
    const promises = [] as Promise<any>[] // eslint-disable-line @typescript-eslint/no-explicit-any
    promises.push(orgStore.ensureOrgsLoaded())
    if (orgName) { promises.push(stackStore.ensureStacksLoaded(orgName)) }
    await Promise.all(promises)
  }

  // identify the user with analytics
  const currentUser = getCurrentUser()
  analytics.identify(currentUser)
  atlas.identify(currentUser)

  // set the current org and stack
  if (orgName) {
    orgStore.setCurrentOrg(orgName)
    if (stackId) stackStore.setCurrentStack({ orgName, stackId })
  }
  document.title = 'Nullstone'
})

router.beforeEach(async (to: RouteLocationNormalized) => {
  // we want to immediately log out if that's our destination
  if (to.name === 'logout') {
    return true
  }

  const currentUser = getCurrentUser()
  if (!currentUser.isAuthenticated) {
    return redirectToLogin(to)
  }
})

export const getOrgName = (to: RouteLocationNormalized): string | undefined => {
  return to.params.orgName as string
}

export const getStackId = (to: RouteLocationNormalized): number | undefined => {
  const stackIdString = to.params.stackId as string || to.query.stackId as string
  if (!stackIdString) { return undefined }
  return parseInt(stackIdString)
}

const hasOrgAccess = (orgName: string | undefined): boolean => {
  // if the orgName isn't in the url, we don't need to check for access
  // because the page won't load anything org specific
  if (!orgName) { return true }
  return authorizeOrgMember(orgName)
}

const hasStackAccess = (orgName: string | undefined, stackId: number | undefined): boolean => {
  // if the orgName or stackId isn't in the url, we don't need to check for access
  // because the page won't load anything org or stack specific
  if (!orgName) { return true }
  if (!stackId) { return true }
  return authorizeStackSoftwareEngineer(orgName, stackId)
}

// if we have made it to this method, we know the user is not authenticated
// unless the page is already the login page or the page does not require authentication,
// we will redirect to the login page
const redirectToLogin = (to: RouteLocationNormalized) => {
  // `requireAuth` is only explicitly set to false if authentication is not required, otherwise it is undefined
  // authentication is required unless `requireAuth` is explicitly set to false
  if (to.name === 'login' || to.meta.requireAuth === false) {
    return true
  } else {
    return { name: 'login', query: { redirect_url: window.location.href } }
  }
}

router.onError(errorHandler)

export default router
